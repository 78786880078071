// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classnames from 'classnames'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ArrowLeft from '../svg/arrow-left'
import ArrowRight from '../svg/arrow-right'

import smallKey from '../../methods/small-key'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Standard */
const Standard = (props) => {
  const {
    lookup = 'coverImage',
    Renderer = ({ node: { [lookup]: asset } }) => (
      <GatsbyImage image={getImage(asset)} />
    ),
    nodes,
    conf = {},
    className,
  } = props

  const sliderConf = {
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    ...conf,
  }

  return (
    <div className={classnames('slider ext-arrow', className)}>
      <Carousel {...sliderConf}>
        {map(nodes, (node) => (
          <Renderer key={smallKey()} node={node} />
        ))}
      </Carousel>
    </div>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Standard
